import React, { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";

import makeStyles from '@material-ui/core/styles/makeStyles';
import { topTitle , aligTitle , topSubTitle , aligSubTitle, sizeTitle, sizeSubTitle } from '../../../config';

am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
    root: {
        padding: "1rem ",
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: 'column',
        height: '80vh',
    },
    wrap: {
        flex: '1 1 auto'
    }
}));

const ReporteParticipacionTotal = () => {
    const classes = useStyles();
    const data = useSelector(state => state.reporteparticipacion.data);
    const agente = useSelector(state => state.reporteparticipacion.filters.dDistribuidor);
    const ola = useSelector(state => state.reporteparticipacion.filters.dOla);
    const [suma, setSuma] = useState(0);
    console.log(data)

    useLayoutEffect(() => {
        let chart = am4core.create("chartdiv", am4charts.PieChart);

        chart.innerRadius = am4core.percent(40);
        chart.startAngle = 5;
        chart.endAngle = 365;

        var title2 = chart.titles.create();
        title2.text =  "Total:  " + Intl.NumberFormat('es-MX').format(suma)+ " /Gls";
        title2.fontSize = sizeSubTitle;
        title2.align =  aligSubTitle;
        title2.marginTop = topSubTitle ;

        var title = chart.titles.create();
        if(agente.length >0)
        {
          title.text = "Participación Tamaño Mercado /Agente: " + agente + ' - Ola: ' + ola
        }
        else 
        {
          title.text = "Participación Tamaño Mercado /Agente: Todos - Ola: " + ola;
        }
        title.fontWeight = 600;
        title.fontSize = sizeTitle;
        title.align = aligTitle;
        title.marginTop = topTitle ;
        title.marginRight = 10;

      
       // titulo.paddingTop = 50;
       // titulo.marginBottom = 60;

        //Add third series
        let pieSeries3 = chart.series.push(new am4charts.PieSeries());
        pieSeries3.dataFields.value = "galones";
        pieSeries3.dataFields.category = "marca";
        pieSeries3.slices.template.stroke = am4core.color("#fff");
        pieSeries3.slices.template.strokeWidth = 2;
        pieSeries3.slices.template.strokeOpacity = 1;
        pieSeries3.slices.template.states.getKey("hover").properties.shiftRadius = 0;
        pieSeries3.slices.template.states.getKey("hover").properties.scale = 1.1;
        pieSeries3.slices.template.propertyFields.fill = "colorHex";

        //borra la lineas
       // pieSeries3.alignLabels = false;
        // pieSeries.labels.template.bent = true;  // semicirculo alrededor
         pieSeries3.labels.template.radius = 6;
       
         pieSeries3.labels.template.padding(0,0,0,0);
       //  pieSeries3.ticks.template.disabled = true;
 
         // Create a base filter effect (as if it's not there) for the hover to return to
         var shadow = pieSeries3.slices.template.filters.push(new am4core.DropShadowFilter());
         shadow.opacity = 0;

          //CUSTOMIZAR  em
          pieSeries3.labels.template.text = "{value.percent.formatNumber('#.0000')}%";
          pieSeries3.labels.template.radius = 9;
          pieSeries3.labels.template.align= "center";
          pieSeries3.labels.template.fill = am4core.color("black");

          pieSeries3.legendSettings.itemValueText = "{valueY.percent}";//em

        //Add data
        chart.data = getData();
        setSuma(0);
         setSuma(getSummary());
        
         chart.legend = new am4charts.Legend();
         chart.legend.marginleft= 50;
         chart.legend.position = "bottom";
         chart.legend.align = "right";
         chart.legend.contentAlign = "right";
         chart.legend.fontSize = 11;
         chart.legend.maxWidth = 200;
         chart.legend.scrollable = true;
         
         if(data.length > 0) {
            chart.exporting.menu = new am4core.ExportMenu();
            chart.exporting.menu.align = "left";
            chart.exporting.menu.verticalAlign = "top"
            chart.exporting.menu.items = [
                {
                  "label": "...",
                  "menu": [
                    {
                      "label": "Imagen",
                      "menu": [
                        { "type": "png", "label": "PNG" },
                        { "type": "jpg", "label": "JPG" },
                        { "type": "svg", "label": "SVG" },
                        { "type": "pdf", "label": "PDF" }
                      ]
                    },  {
                      "label": "Imprimir", "type": "print"
                    }
                  ]
                }
              ]
        }

        return () => {
            chart.dispose();
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data,suma]);

    const getData = () => {
        return _(data)
            .orderBy('orden')
            .groupBy('dMarca')
            .map((item, key) => ({
                'marca': key,
                'colorHex': am4core.color(_.first(item).colorHex),
                'galones': _.sumBy(item, 'galones')
            }))
            .value()
    }

    const getSummary = () => {
        const summary = {
            total: _.sumBy(data, 'galones')
        }
        return summary.total
    }

    return (
        <div className={classes.root}>
             <div id="chartdiv" className={classes.root}>
        </div>
        </div>
    );
};

export default ReporteParticipacionTotal;