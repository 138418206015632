import React, { useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import am4themes_material from "@amcharts/amcharts4/themes/material";

import makeStyles from "@material-ui/core/styles/makeStyles";
import { topTitle , aligTitle , topSubTitle , aligSubTitle, sizeTitle, sizeSubTitle } from '../../../config';


am4core.useTheme(am4themes_material);
am4core.useTheme(am4themes_animated);

const useStyles = makeStyles(() => ({
  root: {
    padding: "1rem ",
    display: "flex",
    flex: "1 1 auto",
    flexDirection: "column",
  },
  // wrap: {
  //   flexWrap: 'auto',

  // }
}));

const ReporteParticipacionLob = () => {
  const classes = useStyles();
  const data = useSelector((state) => state.reporteparticipacion.data);
  const agente = useSelector(state => state.reporteparticipacion.filters.dDistribuidor);
  const ola = useSelector(state => state.reporteparticipacion.filters.dOla);

 const data2 = data.map(k => k.dSegmento )
  const data3 = [...new Set(data2)];
  
  useLayoutEffect(() => {
    var container = am4core.create("chartdiv", am4core.Container);

    container.width = am4core.percent(100);
    container.height = am4core.percent(100);
    container.layout = "vertical";

    if (data.length > 0) {
      container.exporting.menu = new am4core.ExportMenu();
      container.exporting.menu.align = "left";
      container.exporting.menu.verticalAlign = "top";
      container.exporting.menu.items = [
        {
          label: "...",
          menu: [
            {
              label: "Imagen",
              menu: [
                { type: "png", label: "PNG" },
                { type: "jpg", label: "JPG" },
                { type: "svg", label: "SVG" },
                { type: "pdf", label: "PDF" },
              ],
            },
            {
              label: "Imprimir",
              type: "print",
            },
          ],
        },
      ];
     
    }

    _(data)
    .orderBy("dLob")
    .groupBy("dLob")
    .map((item, key) =>    {
      var dataLob = getDataByLob(key)
          return createChart(container, key, getDataByLob(key)) //lo crea de form dinamica
    })
    .value();


    return () => {
      container.dispose();
    };
    // eslint-disable-next-line
  }, [data]);

  const createChart = (container, titleText, data) => {
    if (data.length === 0) {
      return;
    }

    const summary = {
      galones: _.sumBy(data, "galones"),
    };

    var chart = container.createChild(am4charts.PieChart);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 5;
    chart.endAngle = 365;
    chart.height = 620;
    // chart.width = 500;
    // chart.marginleft = 900;

    var title2 = chart.titles.create();
    title2.text =   "Total : " + Intl.NumberFormat("es-MX").format(summary.galones) + " /Gls";
   
    title2.fontSize = sizeSubTitle;
    title2.align =  aligSubTitle;
    title2.marginTop = topSubTitle ;
    //title2.right = 1;
    //title2.paddingTop = 20;

    var title = chart.titles.create();
    if(agente.length >0)
    {
      title.text = "Participación Lob: " + titleText + " /Agente: " + agente + ' - Ola: ' + ola
    }
    else 
    {
      title.text = "Participación Lob: " + titleText + " /Agente: Todos - Ola: " + ola;
    }
    title.fontWeight = 600; 
    title.fontSize = sizeTitle;
    title.align = aligTitle;
    title.marginTop = topTitle ;


    //Add third series
    var series = chart.series.push(new am4charts.PieSeries());

    

    series.dataFields.value = "galones";
    series.dataFields.category = "marca";
    series.slices.template.stroke = am4core.color("#fff");
    series.slices.template.strokeOpacity = 2;
    series.slices.template.strokeWidth = 1;
    series.slices.template.states.getKey("hover").properties.shiftRadius = 0;
    series.slices.template.states.getKey("hover").properties.scale = 1.1;
    series.slices.template.propertyFields.fill = "colorHex";

    series.labels.template.text = "{value.percent.formatNumber('#.0000')}%";
    series.labels.template.radius = 10;
    series.labels.template.align= "center";
    series.labels.template.fill = am4core.color("black");

    series.legendSettings.itemValueText = "{valueY.percent}";//em
    


    if (data.length === 0) {
      var slice = series.slices.template;
      slice.propertyFields.fill = "color";
      slice.propertyFields.fillOpacity = "opacity";
      slice.propertyFields.stroke = "color";
      slice.propertyFields.strokeDasharray = "strokeDasharray";
      slice.propertyFields.tooltipText = "tooltip";

      series.labels.template.propertyFields.disabled = "disabled";
      series.ticks.template.propertyFields.disabled = "disabled";
    }

    chart.legend = new am4charts.Legend();
    chart.legend.marginleft= 50;
    chart.legend.position = "bottom";
    chart.legend.align = "right";
    chart.legend.contentAlign = "right";
    chart.legend.fontSize = 9;
    chart.legend.maxWidth = 200;
    chart.legend.scrollable = true;
 
    chart.data =
      data.length > 0
        ? data.sort((a, b) => b.galones - a.galones)
        : [
            {
              dMarca: "Dummy",
              disabled: true,
              galones: 1000,
              color: am4core.color("#dadada"),
              opacity: 0.3,
              strokeDasharray: "4,4",
              tooltip: "",
            },
          ];
        // rellena con rectangulo para espacio
          let rect = container.createChild(am4core.Rectangle);
          rect.width = am4core.percent(50);
          rect.height = 100;
          rect.fill = 'white';
  };

  const getDataByLob = (lob) => {
    return _(data.filter((f) => f.dLob === lob))
      .groupBy("dMarca")
      .map((item, key) => ({
        marca: key,
        colorHex: am4core.color(_.first(item).colorHex),
        galones: _.sumBy(item, "galones"),
      }))
      .value();
  };

  return (
    <>
      <div>
          <div style={{ height: data3.length * 400, width: 1000  }} className={classes.root} id="chartdiv" />  
      </div>
    </>
  );
};

export default ReporteParticipacionLob;
